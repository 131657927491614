<template>
  <ModalWrapper
    :visible="visible"
    @after-enter="$emit('after-enter')"
    @after-leave="$emit('after-leave')"
  >
    <div ref="modal" :class="$style.DirectionsModal">
      <div class="modal-header" :class="$style.header">
        <h1 class="h4">{{ t("Выберите валюту") }}</h1>
        <SvgoIconClose class="icon-close" @click="$emit('close')" />
      </div>

      <div :class="$style.wrapper">
        <VInput
          v-model="search"
          :placeholder="t('Поиск по названию')"
          leading-icon
          size="small"
        >
          <template #leading>
            <SvgoIconSearch :class="$style.icon" />
          </template>
        </VInput>
        <transition name="fade-fast">
          <DirectionsFilter
            v-if="!pending"
            v-model="activeFilter"
            :items="filterItems"
          />
        </transition>
      </div>
      <DirectionsList
        v-if="data.directions"
        label-name="name"
        value-name="id"
        :options="searchDirections"
        :active="data.activeName"
        :class="$style.list"
        :reserves="reserves"
        @on-selected="onChange"
      />
    </div>
  </ModalWrapper>
</template>

<script setup lang="ts">
import { useFuse } from "@vueuse/integrations/useFuse";
import DirectionsFilter from "~/components/common/DirectionsFilter.vue";
import DirectionsList from "~/components/common/DirectionsList.vue";
import type { Direction } from "~/types/calculator";
import { calculatorApi } from "~/utils/api/calculator";
interface DirectionsModalProps {
  visible: boolean;
  data: {
    directions: Direction[];
    activeName: string;
    onCurrencyChange: (id: number) => void;
  };
}

const props = defineProps<DirectionsModalProps>();

const { t } = useI18n({
  useScope: "local",
});

const emit = defineEmits(["close", "after-leave", "after-enter"]);

const modal = ref(null);

onClickOutside(modal, () => {
  emit("close");
});

function onChange(id: number) {
  props.data.onCurrencyChange(id);
  emit("close");
}

const { data: reserves } = await calculatorApi.getReserves();

const activeFilter = ref("all");
const { data: filters, pending } = await calculatorApi.fetchSettings();

const filterItems = computed(() => {
  if (!filters.value)
    return [
      {
        name: "Все",
        code: "all",
      },
    ];
  return filters.value.filters.main;
});

const filteredDirections = computed(() => {
  if (!props.data.directions) return [];
  if (activeFilter.value === "all") return props.data.directions;
  return props.data.directions.filter((item) => {
    const filter = item.filter[0];
    return filter === activeFilter.value;
  });
});

const search = ref("");

const { results } = useFuse(search, filteredDirections, {
  fuseOptions: { keys: ["keywords", "name"] },
  matchAllWhenSearchEmpty: true,
});

const searchDirections = computed(() => {
  return results.value.map((item) => item.item);
});
</script>

<i18n lang="json">
{
  "ru": {
    "Выберите валюту": "Выберите валюту",
    "Поиск по названию": "Поиск по названию"
  },
  "en": {
    "Выберите валюту": "Choose a currency",
    "Поиск по названию": "Search by name"
  }
}
</i18n>

<style lang="scss" module>
.DirectionsModal {
  max-width: 40rem;
  width: 100%;
  max-height: 55.6rem;
  height: 55.6rem;
  overflow: hidden;
  border-radius: 20px;
  background-color: var(--main-block-bg);
  padding: 0 0 2rem;
  position: relative;
  box-sizing: content-box;
}

.icon {
  width: 2rem;
  color: var(--text-color-6);
}

.header {
  padding: 2rem;
}

.input {
  margin-bottom: 1.5rem;

  @include respond-to(xs) {
    margin-bottom: 10px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0 2rem 2rem;
  border-bottom: 1px solid var(--text-color-2);
  min-height: 140px;

  @include respond-to(xs) {
    padding: 0 20px 15px;
    min-height: 135px;
  }
}

.list {
  max-height: 35.2rem;
}

.close {
  top: 2rem;
  right: 2rem;
}
</style>
